

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.landingPageTitle {
  font-family: 'Montserrat', sans-serif;
  font-size: 10vw;
  color: #e5e4e2;
  letter-spacing: 20px;
  font-weight: 900;

}
.landingPageSubTitle {
  font-family: 'Montserrat', sans-serif;
  font-size: 2.5vw;
  color: #e5e4e2;
  letter-spacing: 20px;
  font-weight: 700;
  padding-right: 40px;
  padding-left: 40px;
  padding-top: 20px;

}

.container {
  font-family: 'Montserrat', sans-serif;
  color: #e5e4e2;

}

.active {
  color: #ff5231 !important;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    /*animation: App-logo-spin infinite 20s linear;*/
  }
}

.App-header {
  background-color: #000000;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #e5e4e2;
}

.bg-dark {
    background-color: #0d3654 !important;
}

 .container {
     /*background-color: #0d3654;*/
 }

.App-link {
  color: #ff5231;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
